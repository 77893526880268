<template>
  <v-autocomplete
    v-model="value"
    :items="options"
    :label="label"
    :placeholder="placeholder"
    :disabled="disabled"
    dense
    outlined
    clearable
    hide-details
    single-line
  ></v-autocomplete>
</template>

<script>
import { E_MARKETS } from "@/libs/const";
import { debounce } from "lodash/function";

export default {
  name: "SelectEMarket",
  props: {
    name: {
      type: String,
      default: () => "",
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
    label: {
      type: String,
      default: () => "Sàn",
    },
    placeholder: {
      type: String,
      default: () => "Sàn",
    },
    filters: {
      type: Object,
      default: () => {},
    },
    isFilter: {
      type: Boolean,
      default: () => false,
    },
  },
  data: () => ({
    value: null,
    options: [],
  }),
  computed: {},
  watch: {
    value() {
      this.onFilter();
    },
  },
  methods: {
    onFilter: debounce(function () {
      this.$emit("onFilter", {
        name: this.name,
        value: this.value,
      });
    }, 100),
    getList() {
      // const { data } = await httpClient.post("/list-e-market");
      const options = [...E_MARKETS];
      if (this.isFilter) {
        options.push(
          {
            value: "none",
            text: this.$t("labels.order_source_0"),
          },
          {
            value: "transfer",
            text: this.$t("labels.internal_transfer"),
          }
        );
      }
      this.options = [...options];
    },
  },
  mounted() {
    this.getList();
  },
};
</script>
